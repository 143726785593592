<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col">
        <h1 class="title line-right">{{ $t('churches.default') }}</h1>
      </div>
    </div>
    <!--<div class="row">-->
      <!--<div class="col">-->
        <div class="card-deck church-deck flex-wrap wd-sm-100p">
          <template v-for="church in churches" >
            <div v-bind:key="church.id" class="card">
              <!-- <img v-bind:src="church.logoUrl" class="card-img-top" v-bind:alt="church.name"> -->
              <div class="card-img-top">
                <div class="d-none">{{church.name}}</div>
                <img v-bind:src="church.logoUrl" class="img-fit-contain" v-bind:alt="church.name" />
              </div>
              <div class="card-body">
                <router-link :to="{ name:'churches.show', params:{id: church.id, name: slugify(church.name)}}" class="stretched-link"></router-link>
                <h5 class="card-title">{{church.name}}</h5>
                <p class="card-text">
                  {{church.city}} {{church.state}}
                </p>
              </div>
            </div>
          </template>
        </div>
      <!--</div>&lt;!&ndash; .col &ndash;&gt;-->
    <!--</div>&lt;!&ndash; .row &ndash;&gt;-->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    layout: 'default',
    metaInfo () {
      return { title: this.$t('churches.default') }
    },
    components: {
  //    'sermon-grid': SermonGrid
    },
    data: () => ({
      title: window.config.appName
    }),
    created () {
      this.$store.dispatch('public/fetchChurches');
    },
    computed: {
      ...mapGetters({
        churches: 'public/churches'
      })
    }
  }
</script>
<style lang="scss">
  .card-img-top {
    background: no-repeat center center;
    background-size: contain;
    height: 10rem;
  }
</style>
<style lang="scss" scoped>
.img-fit-contain {
  width: 100%;
  height: 100%;
  object-fit: contain; }
.church-deck {
  .card {
    position:relative; 
    margin-bottom: 1em;
    @include media-breakpoint-up(sm) {
      max-width: calc(50% - 30px);
      flex-basis: calc(50% - 30px);
    }
    @include media-breakpoint-up(lg) {
      max-width: calc(33% - 30px);
      flex-basis: calc(33% - 30px);
    }
  } 
}
</style>